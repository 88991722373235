:root {
    --container-max-columns: #{$sm-down-columns};
    --grid-vertical-gap: #{box-model(l)};
}

html, body, #app {
    height: 100%;
}

input, textarea {
    /// Mobile safari auto-zooms on control fields that
    /// declares a font-size smaller than 16px.
    /// To prevent that we increase the font size here.
    @include font-size(document);
}

.sm-down-only {
    display: block !important;
}

.sm-down-only-inline {
    display: inline-block !important;
}
