section.session {
    .session-dates {
        position: relative;
        margin-bottom: box-model(m);
    }

    .prev, .next {
        cursor: pointer;
        position: absolute;
        top: 0;
        bottom: 0;
        width: box-model(xl) * 2;
        z-index: 1;
        padding: box-model(xxs);

        &:hover {
            i {
                background-color: color(utility-200);
            }
        }

        i {
            @include icon-size(xs);

            color: color(brand-300);
            vertical-align: middle;
            border-radius: 100%;
            background-color: color(base-000);
        }
    }

    .prev {
        left: 0;
        background: linear-gradient(275deg, transparent 40%, #{color(base-000)} 60%);
    }

    .next {
        right: 0;
        text-align: right;
        background: linear-gradient(90deg, transparent 40%, #{color(base-000)} 60%);
    }

    .dates-viewport {
        overflow: hidden;
    }

    .dates {
        display: inline-flex;
        flex-wrap: nowrap;
        gap: box-model(xs);
        padding: box-model(xxxs);

        .btn {
            overflow: auto;
            text-overflow: unset;
        }
    }

    .date.btn-primary {
        cursor: default;
    }
}

.session-details {
    background-color: color(base-000);
    border: 1px solid color(base-100);
    margin-bottom: box-model(s);
    border-radius: $base-border-radius;
    padding: box-model(m);

    &.has-action:hover {
        border-color: color(primary);
    }

    &.has-description:hover:not(.condensed) {
        cursor: pointer;
        border: 1px solid color(primary);
    }

    &:last-child, .session-details:last-child {
        margin-bottom: 0;
    }

    &.master {
        background-color: color(base-050);
        border-color: color(base-100);
    }

    &.condensed {
        padding: 0;
        border: 0;

        &.master, .session-details {
            padding: box-model(s, m);
        }

        .speaker-card {
            flex: 0 1 auto;

            picture {
                flex: 0 0 icon-size(xs);
            }

            picture img,
            svg {
                width: icon-size(xs);
                height: icon-size(xs);
                line-height: icon-size(xs);
            }

            .details {
                display: flex;
            }

            .title {
                @include font-weight(normal);
                color: color(base-300);
                margin-bottom: 0;
                align-self: center;
            }
        }
    }

    > .expandable-list + .expandable-list:has(.open) {
        margin-top: box-model(m);
    }

    .session-details {
        margin-bottom: box-model(xs);
    }

    header:has(~ .expandable-list) {
        margin-bottom: box-model(xs);
    }

    h4 {
        @include font-size(document);
        @include line-height(document);
        margin-bottom: box-model(xxs);

        a {
            display: block;

            &:hover {
                color: inherit;
            }

            &.open i {
                transform: rotate(180deg);
            }
        }

        i {
            float: right;
        }
    }

    .time {
        @include font-size(s);
        @include line-height(s);
        color: color(base-400);
    }

    .more {
        @include font-size(s);
        @include line-height(s);

        text-align: left;
        margin-top: box-model(s);

        i {
            margin-left: box-model(xs);
        }
    }

    .speaker-card {
        border: 0;
    }
}

.session-details-modal {
    overflow-wrap: break-word;

    .close {
        margin-top: box-model(s) * -1;
        margin-right: box-model(m) * -1;
        color: color(base-400);
    }

    .modal-header {
        padding-bottom: 0;
    }

    .session-details {
        margin-bottom: box-model(m);
    }

    .desc {
        border-top: 1px solid color(base-100);
        padding-top: box-model(m);
    }
}
