
// Custom props
// ------------

:root {
    // Layout
    --container-max-width: #{$screen-lg-min};
    --container-max-columns: #{$grid-columns};

    /// This is the equivalent of #FF9500 in HSL color code.
    /// These values are then replaced with the one coming from
    /// the theme.
    --utility-color: 35, 100%;
    --utility-color-light: 50%;

    // Grid
    --grid-vertical-gap: #{box-model(xxl)};
}
