$base-box-shadow: box-model(0, xxs, xs, 0) rgba(0, 0, 0, 0.1);

$date-picker-cell-size: box-model(xl);
$date-picker-min-width: box-model(xs) * 23; // 184px when $base-unit = 8px
$date-picker-box-shadow: $base-box-shadow;

@import 'vue2-datepicker/scss/index';

/**
 * Datepicker
 *
 * @see https://github.com/mengxiong10/vue2-datepicker
 */


.mx-input {
    @extend %form-control;

    height: auto;
    box-shadow: none;
    font-size: inherit;
    color: inherit;
}

.mx-input-append {
    vertical-align: middle;
    text-align: center;
    line-height: 22px; // icon specific, no need for mixin
}

.mx-datepicker.has-error .mx-input-wrapper {
    input {
        border-color: color(error-300);

        &:focus {
            box-shadow: 0 0 0 1px color(error-300);
        }
    }
}

.mx-datepicker-popup {
    box-shadow: $date-picker-box-shadow;
    border: 1px solid color(base-100);
    border-radius: $base-border-radius;
    padding: box-model(xs);
    margin-top: box-model(xs);
    min-width: $date-picker-min-width;
}

.mx-range-wrapper {
    display: flex;
    width: 100%;
}

.mx-shortcuts-wrapper {
    @include font-size(s);
    display: flex;

    .mx-shortcuts {
        flex: 1 1 25%;
        margin-bottom: box-model(0, xs, xs);

        &::after {
            display: none;
        }
    }

}

.mx-calendar {
    color: color(base-700);
    padding: box-model(xxs);
    flex: 1 1 50%;
    float: none;
}

// Hides year buttons in date mode
.mx-calendar-header {
    color: color(base-700);
}

.mx-table {
    @include font-size(s);
    min-width: box-model(xs) * 23; // 184px if box-model(xs) = 8px

    th {
        @include font-size(s);
        @include font-weight(normal);
        background-color: transparent;
        text-transform: uppercase;
        text-align: center;
    }

    td, th {
        width: $date-picker-cell-size;
        height: $date-picker-cell-size;
        line-height: $date-picker-cell-size;
    }

    td {
        @include line-height(l);
        border-radius: $date-picker-cell-size;

        &.last-month, &.next-month {
            color: color(base-700);
            opacity: .6;
        }
    }
}

.mx-table-date .today {
    background-color: color(utility-200);
    color: color(button-text);
}

.mx-calendar-content,
.mx-panel {
    height: auto;
    width: auto;
}

.mx-datepicker-main,
.mx-btn {
    color: color(base-700);
}

.mx-btn:hover {
    color: color(utility-300);
}

.mx-calendar-content,
.mx-calendar-time {
    .cell,
    .mx-time-item {
        &:hover {
            color: inherit;
            background-color: color(utility-100);
        }

        &.disabled, &.disabled:disabled {
            background-color: transparent;
        }

        &.active {
            background-color: color(utility-400);
            color: color(base-000);
        }

        &.in-range:not(.disabled) {
            background-color: transparent;
            position: relative;
            border-radius: 0;

            &::before {
                content: '';
                display: block;
                height: 4px;
                background-color: color(utility-100);
                width: 100%;
                top: 50%;
                left: 0;
                margin-top: -2px;
                position: absolute;
                z-index: -1;
            }
        }
    }
    .mx-time-item.disabled {
        display: none;
    }
}

.mx-panel-year, .mx-panel-month {
    display: flex;
    flex-wrap: wrap;

    .cell {
        @include line-height(xs);
        flex: 1 1 30%;
        width: auto;
        margin: box-model(xxxs);
        border-radius: $base-border-radius;
        background-color: color(utility-300);
        color: color(base-000);
        padding: math.div(box-model(s), 2) box-model(s);

        &:not(.disabled):hover {
            background-color: color(utility-300);
            color: color(base-000);
        }

        &:not(.disabled):active {
            background-color: color(utility-500);
            color: color(base-000);
        }
    }
}

// Disable the animation
.mx-zoom-in-down-enter-active,
.mx-zoom-in-down-leave-active {
  opacity: 1;
  transform: none;
  transition: none;
}

.mx-zoom-in-down-enter,
.mx-zoom-in-down-leave-to {
  opacity: 1;
  transform: none;
}

.mx-time-column .mx-time-list::after {
    height: auto;
}
