.main-banner {
    padding: box-model(0, 0, m);

    .page-wrapper {
        row-gap: 0;
    }

    figure {
        margin: 0;
    }

    picture {
        display: block;
        border-radius: $base-border-radius;
        overflow: hidden;
        font-size: 0;
        line-height: 0;
    }

    picture.full-width {
        border-radius: 0;

        > img {
            width: 100%;
        }
    }
}
