.speakers {
    gap: box-model(xs);

    .speaker-card {
        @include flexWithGap(0, 0, box-model(m), 2);
    }
}

.speaker-details .modal-header {
    max-height: none;
}
