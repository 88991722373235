header.main {
    background-color: color(base-000);
    padding: box-model(s, 0);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    position: sticky;
    top: 0;
    z-index: 1;

    .actions {
        float: right;
        margin-left: box-model(xs);
    }

    .actions, > .container > .menu {
        display: flex;
        column-gap: box-model(xs);
    }

    span.separator {
        display: inline-block;
        border-left: 1px solid color(base-100);
        height: box-model(xl);
        margin: box-model(0, xs);
    }

    .menu > .logout-button {
        display: none;
    }
}
