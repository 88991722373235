.vue-dialog-buttons {
    @extend .modal-footer;
}

.vue-dialog .vue-dialog-buttons {
    border-top: 0;
}

.dialog-content {
    @extend .modal-body;
    padding: box-model(l, xxl) !important;
}
