
.session-control {
    .availability {
        float: none;
    }

    label {
        &.radio, &.checkbox {
            column-gap: 0;
        }
    }
}
