.registration {

    &.sign-up {
        display: block;
    }

    &.warning-wall {
        main {
            > .container.page-wrapper {
                padding-bottom: 0;
            }
        }
    }

    .funnel-end {
        width: 75vw;
        .continue-to-branding {
            margin-top: box-model(m);
            display: block;
        }
    }

    main > .container.page-wrapper {
        display: block;
        padding-bottom: box-model(xl) * 2;
    }
}
