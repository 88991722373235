.pin-input {
    .pin-card {
        text-align: center;

        h2, p {
            width: 100%;
            text-align: left;
        }

        input {
            display: inline-block;
            width: box-model(xl);
            margin-left: box-model(s);
            padding: box-model(xxs);
            text-align: center;
            &:first-of-type {
                margin-left: 0;
            }
        }

        .form-control.error {
            border: 2px solid color(error-300);
        }

        .resend-container {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 16px;

            a {
                color: color(primary);
                cursor: pointer;
                text-decoration: underline;
            }

            .success-message {
                color: color(success-500);
                font-size: 12px;
                display: flex;
                align-items: center;
            }
            
            .success-message i {
                margin-right: 5px;
            }

            .error-message {
                color: color(error-300);
                font-size: 12px;
            }
        }

        .error {
            font-size: 12px;
            color: color(error-300);
            margin-top: box-model(s);
        }
    }
}
