.speakers {
    $gap: box-model(m);

    display: flex;
    flex-wrap: wrap;
    gap: $gap;

    .speaker-card {
        @include flexWithGap(0, 0, $gap, 3);
    }
}

.speaker-card {
    display: flex;
    gap: box-model(xs);
    min-width: 0;
    box-sizing: border-box;
    border: 1px solid;
    border-color: color(base-100);

    picture {
        display: block;
        flex: 0 0 box-model(xs) * 7;
        font-size: 0;
        line-height: 0;

        img {
            height: box-model(xs) * 7;
            width: box-model(xs) * 7;
            object-fit: cover;
            border-radius: 100%;
        }
    }

    svg {
        background-color: color(primary);
        border-radius: 100%;

        path {
            fill: var(--button-foreground-color);
        }
    }

    .details {
        @include font-size(s);
        @include line-height(s);

        flex: 1 1 100%;
        min-width: 0;

        > * {
            @include ellipsis;
        }
    }

    .title {
        @include font-weight(medium);
        margin-bottom: box-model(xxxs);
    }

    .subtitle {
        color: color(base-300);
        margin-bottom: box-model(xxxs);
    }
}

section.presenter {
    .speaker-card {
        display: block;
        border-radius: $base-border-radius;
        padding: box-model(m, xs);
        text-align: center;

        picture {
            margin-bottom: box-model(xs);

            img {
                @include icon-size(xxxl);
            }
        }

        .details {
            @include font-size(s);
            @include line-height(s);

            display: block;

            > * {
                @include clamp(2);
            }

            .title {
                @include font-size(base);
                @include line-height(base);
            }

            .title, .subtitle {
                margin-bottom: box-model(xxxs);
            }
        }
    }
}

section.session {
    .speaker-card svg {
        @include icon-size(xl);
    }
}

.speaker-details {
    overflow-wrap: break-word;

    .modal-header {
        text-align: center;
        padding: 0;
        flex: 1 1 20%;
    }

    .modal-body {
        padding-top: box-model(s);
        flex: 1 1 80%;
    }

    .picture-bg {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        background-color: color(primary);
        height: (box-model(xs) * 11);
    }

    picture {
        display: block;
        margin: box-model(xxl) auto 0;
        font-size: 0;
        line-height: 0;
        position: relative;
        z-index: 1;

        img {
            border: box-model(xxxs) solid color(base-000);
            height: box-model(xs) * 10;
            width: box-model(xs) * 10;
            object-fit: cover;
            border-radius: 100%;
        }
    }

    svg {
        background-color: color(primary);
        border-radius: 100%;
        border: box-model(xxxs) solid color(base-000);
        height: box-model(xs) * 10;
        width: box-model(xs) * 10;

        path {
            fill: var(--button-foreground-color);
        }
    }

    .details {
        text-align: center;
        padding-bottom: box-model(m);
        margin: box-model(0, m);
        border-bottom: 1px solid color(base-100);

        .title {
            @include font-weight(medium);
            @include font-size(base);
            @include line-height(base);

            margin-bottom: box-model(xxxs);
        }

        .subtitle {
            color: color(base-300);
            margin-bottom: box-model(xxxs);
        }
    }

    .close {
        margin: 0;
        top: box-model(l);
        right: box-model(l);
        position: absolute;
        z-index: 2;
    }
}
