%btn {
    @include font-size(base);
    @include line-height(document);
    @include font-weight(medium);
    @include ellipsis(true);

    appearance: none;
    background-color: color(base-050);
    border-radius: $base-border-radius;
    border: 0;
    color: color(base-500);
    cursor: pointer;
    display: inline-block;
    padding: box-model(xxs, s);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    transition:
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out,
        color 0.15s ease-in-out,
        opacity 0.15s ease-in-out;

    &:hover {
        background-color: color(base-100);
        text-decoration: none;
    }

    &:active {
        background-color: color(base-200);
    }

    &:focus, &:focus-visible {
        box-shadow: 0 0 0 2px color(utility-400);
        outline: none;
    }

    &[disabled],
    &:disabled,
    &.disabled {
        opacity: .5;
        cursor: not-allowed;
        color: color(base-200);
        background-color: color(base-050);
    }

    &.btn-icon {
        [class^='icon-'], [class*=' icon-'] {
            @include font-size(document);

            display: inline-block;
            margin: box-model(box-model(xxs) * -1, xs, 0, 0);
            vertical-align: middle;
        }

        &.single {
            width: icon-size(s);
            padding-left: math.div(box-model(s), 2);
            padding-right: math.div(box-model(s), 2);

            [class^='icon-'], [class*=' icon-'] {
                margin-right: 0;
            }
        }
    }

    &.btn-primary {
        background-color: color(primary);
        color: color(button-text);
    }

    &.important {
        display: block;
        width: 100%;
        box-sizing: border-box;
    }

    &.anchor {
        background-color: transparent;
        color: color(base-300);

        &.active, &:hover {
            background-color: color(base-050);
            color: inherit;
        }
    }

    &.btn-medium {
        padding: box-model(xs, s);
    }
}

.btn-primary {
    background-color: color(utility-400);
    color: color(button-text);

    &:hover {
        background-color: color(utility-450);
        color: color(button-text);
    }

    &:active {
        background-color: color(utility-500);
    }

    &:focus, &:focus-visible {
        box-shadow: 0 0 0 2px color(utility-500);
    }

    &[disabled], &.disabled {
        background-color: color(utility-400);
        color: color(button-text);
        opacity: .5;
    }

    &.btn-loader {
        .spinner {
            filter: brightness(10);
        }
    }
}

.btn, .btn-primary {
    @extend %btn;
}

.close {
    @include iconify(icon-cross);

    appearance: none;
    border: 0;
    background-color: transparent;
    color: color(base-100);
    display: block;
    cursor: pointer;
    float: right;
    height: $close-button-size;
    width: $close-button-size;
    line-height: $close-button-size;
    text-align: center;
    transition: color .2s linear;

    // Following units are hardcoded because
    // they are really specific to the icon used.
    margin-top: -20px;
    margin-right: -28px;

    &:hover {
        color: color(base-500);
    }
}

.login-button {
    i {
        height: icon-size(xxs);
        width: icon-size(xxs);
        display: inline-block;
        margin-left: box-model(xs);
        vertical-align: middle;
    }
}
