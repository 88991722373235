%card {
    background-color: color(base-000);
    border-radius: $large-border-radius;
    border: 1px solid color(base-100);
    box-shadow: box-model(0, 0, m) rgba(color(base-700), 0.08);
    margin-bottom: box-model(m);
    padding: box-model(m);

    &:last-child {
        margin-bottom: 0;
    }

    .alt & {
        border: 0;

        %card {
            border: 1px solid color(base-100);
        }
    }
}

%large-cards {
    @extend %card;

    padding: box-model(l);
}

.event-card {
    @extend %card;

    .card-details-container {
        display: flex;
        column-gap: box-model(m);
        align-items: center;
        overflow-wrap: anywhere;
    }

    h1, h2 {
        @include font-size(l);
        @include line-height(l);

        margin-bottom: box-model(xxs);
    }

    .date {
        color: color(base-300);
    }

    .logo {
        line-height: 0;
        font-size: 0;
        flex: 0 0 box-model(h);
        overflow: hidden;

        img {
            border-radius: $large-border-radius;
        }
    }

    .details {
        flex: 1 1 auto;
    }

    .calendar-button {
        float: right;
    }
}

.registration-card {
    @extend %card;

    h2 {
        @include font-size(l);
        @include line-height(xl);
    }

    p {
        color: color(base-300);
    }

    .sso {
        padding-top: box-model(m);
    }

    form ~ .sso {
        padding-top: 0;
    }

    .forbidden {
        font-weight: font-weight(bold);
        margin-top: box-model(s);
    }

    .retrieve-reg {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 16px;

        a {
            color: color(primary);
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .retrieve-reg-back {
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 1em;
    }
}

.update-profile-card {
    @extend %card;

    h1, h2 {
        @include font-size(l);
        @include line-height(l);

        margin-bottom: box-model(xxs);
    }

    p {
        color: color(base-300);
        margin-bottom: box-model(s);

        &:last-child {
            margin-bottom: 0;
        }
    }

    .rsvp {
        .answers {
            display: flex;
            gap: box-model(s);

            button {
                flex: 1 1 auto;
            }
        }
    }
}

.pin-card {
    @extend %card;

    h2 {
        @include font-size(l);
        @include line-height(l);

        margin-bottom: box-model(m);
    }

    p {
        margin-bottom: box-model(s);

        &:last-child {
            margin-bottom: 0;
        }
    }
}
