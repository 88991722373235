.sessions-registration {
    margin-bottom: box-model(m);

    .expandable-content {
        border-radius: $base-border-radius;
        border: 1px solid color(base-100);
        background-color: color(base-050);

        .heading {
            padding: box-model(m);
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.has-errors {
        .expandable-content {
            border-color: color(error-300);
        }

        > .session-control .inner-content {
            border-color: color(error-300);
        }
    }

    > .error {
        @include font-size(s);

        margin-top: box-model(s) * -1;
        color: color(error-300);

        i {
            display: inline-block;
            margin-right: box-model(xxs);
            margin-top: - box-model(xxxs);
            vertical-align: middle;
        }
    }
}
