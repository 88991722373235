.icon-gcal, .icon-cal {
    width: box-model(l);
    height: box-model(l);
    display: inline-block;
    vertical-align: middle;
}

.icon-gcal {
    background-image: image-url("google_cal.svg");
}

.icon-cal {
    background-image: image-url("generic_calendar.svg");
}
