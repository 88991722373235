@each $alignment in center, left, right, justify {
    .ql-align-#{$alignment} {
        text-align: $alignment;
    }
}

$LIST_COUNTERS: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
$LIST_STYLE: decimal lower-alpha lower-roman;
$LIST_STYLE_WIDTH: 1.2rem;
$LIST_STYLE_MARGIN: 0.3rem;
$LIST_STYLE_OUTER_WIDTH: $LIST_STYLE_MARGIN + $LIST_STYLE_WIDTH;
$MAX_INDENT: 9;

@for $num from 1 through $MAX_INDENT {
    .ql-indent-#{$num}:not(.ql-direction-rtl) {
        padding-left: $num * 3rem;
    }

    li.ql-indent-#{$num}:not(.ql-direction-rtl) {
        padding-left: ($num * 3rem) + $LIST_STYLE_OUTER_WIDTH;
    }

    .ql-indent-#{$num}.ql-direction-rtl.ql-align-right {
        padding-right: $num * 3rem;
    }

    li.ql-indent-#{$num}.ql-direction-rtl.ql-align-right {
        padding-right: ($num * 3rem) + $LIST_STYLE_OUTER_WIDTH;
    }
}

.ql-direction-rtl {
    direction: rtl;
}

.ql-list {
    margin: box-model(0, 0, 0);
    list-style-type: none;

    > li::before {
        content: '\2022';
        display: inline-block;
        white-space: nowrap;
        width: 1.2em;
    }

    li {
        &:not(.ql-direction-rtl)::before {
            margin-left: -1.5rem;
            margin-right: 0.3rem;
            text-align: right;
        }
    }
}

ol.ql-list {
    list-style-type: none;

    li {
        counter-reset: $LIST_COUNTERS;
        counter-increment: list-0;

        &::before {
            content: counter(list-0, decimal) '. ';
        }

        @for $num from 1 through $MAX_INDENT {
            &.ql-indent-#{$num} {
                counter-increment: list-#{$num};

                &::before {
                    content: unquote('counter(list-#{$num}, #{ nth($LIST_STYLE, ($num % 3) + 1) })') '. ';
                }
            }

            @if $num < $MAX_INDENT {
                &.ql-indent-#{$num} {
                    counter-reset: slice($LIST_COUNTERS, $num + 1, $MAX_INDENT);
                }
            }
        }
    }
}

