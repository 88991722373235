body .v--modal {
    box-shadow: none;
    border-radius: $large-border-radius;
}

.v--modal-background-click {
    height: 100% !important;
}

.v--modal-overlay {
    background: rgba(0, 0, 0, $modal-backdrop-opacity);
    bottom: 0;
    right: 0;
}

.v--modal-box {
    background-color: color(base-000);
    padding: 0;
    max-height: $modal-base-max-height;
    border-radius: $large-border-radius;
    transform: translateY(-50%);

    // Following !importants due to inline styles
    top: 50% !important;
    left: 50% !important;
    width: $modal-width-small !important;
    margin-left: math.div($modal-width-small, -2) !important;
    transition: $modals-width-transition;

    &.medium {
        width: $modal-width-medium !important;
        margin-left: math.div($modal-width-medium, -2) !important;
    }

    &.large {
        width: $modal-width-large !important;
        margin-left: math.div($modal-width-large, -2) !important;
    }

    p {
        @include line-height(base);
    }
}

.modal-wrapper {
    display: flex;
    flex-direction: column;
    max-height: $modal-base-max-height;

    .modal-header {
        flex: 1 1 10%;
    }

    .modal-body {
        flex: 1 1 85%;
        overflow: auto;
    }

    .modal-footer {
        flex: 1 1 5%;
    }
}

.modal-header {
    padding: box-model(l, xl, m);

    h3 {
        border: 0;
        padding: 0;
        margin: 0;
    }
}

.modal-body {
    box-sizing: border-box;
    padding: box-model(l, xl, m);
    overflow: auto;

    &:last-child {
        padding-bottom: box-model(l);
    }
}

.modal-header + .modal-body {
    padding-top: 1px;
    margin-top: -1px;
}

.modal-body + .modal-footer {
    padding-top: 0;
}

.modal-footer {
    padding: box-model(l, xl);
    text-align: right;

    > .btn {
        margin-left: box-model(xs);
        margin-right: box-model(xs);

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}
