.container {
    @include clearfix;

    margin: 0 auto;
    max-width: var(--container-max-width);
    min-width: $screen-xs-min;
    padding-left: box-model(m);
    padding-right: box-model(m);
    box-sizing: border-box;

    &.page-wrapper {
        @include grid(var(--container-max-columns), 0);
    }
}

main {
    padding: box-model(m, 0, xl);
}
