header.main {
    aside.actions {
        justify-content: flex-end;
        flex-wrap: wrap;
        flex: 1 1 100%;
        gap: box-model(xs);

        > .calendar-button {
            display: none;
        }

        &.has-language-selector {
            > .logout-button {
                display: none;
            }
        }
    }

    span.separator {
        display: none;
    }

    .toggler {
        margin-right: box-model(xs);
    }

    > .container {
        display: flex;
        flex-direction: row-reverse;
        position: relative;

        > .menu {
            background: color(base-000);
            border-radius: $base-border-radius;
            box-shadow: box-model(0, xxs, s) rgba(0, 0, 0, 0.25);
            flex-direction: column;
            margin-top: box-model(xs);
            min-width: box-model(xxl) * 4;
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            padding: box-model(xxs);
            position: absolute;
            top: 100%;
            left: box-model(m);

            transition:
                max-height .2s ease-in-out,
                opacity .2s ease-in-out;

            &.in {
                max-height: box-model(xs) * 17;
                opacity: 1;
            }

            a, .logout-button {
                @include font-weight(normal);

                text-align: left;
                background: color(base-000);
            }

            .logout-button {
                display: block;
                border-top: solid 1px color(base-100);
                border-radius: 0;
                margin-top: box-model(xxs);
                padding-top: box-model(xxs);
            }
        }
    }
}
