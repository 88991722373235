// Grids
// -----

$breakpoints: (
    xs: 320px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px,
);

// Media queries ranges
// --------------------

$screen-xs-min: breakpoint(xs);
$screen-xs-max: breakpoint(sm) - 1;

$screen-sm-min: breakpoint(sm);
$screen-sm-max: breakpoint(md) - 1;

$screen-md-min: breakpoint(md);
$screen-md-max: breakpoint(lg) - 1;

$screen-lg-min: breakpoint(lg);
$screen-lg-max: breakpoint(xl) - 1;

$screen-xl-min: breakpoint(xl);
$screen-xl-max: breakpoint(xxl) - 1;

$screen-xxl-min: breakpoint(xxl);

// Grid columns
// ------------

$grid-columns: 12;
$md-down-columns: 6;
$sm-down-columns: 2;
