// Typography
// ----------

$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$secondary-font: Consolas, monospace;

$font-sizes: (
    xs: 10px,
    s: 12px,
    base: 14px,
    document: 16px,
    m: 18px,
    l: 20px,
    xl: 24px,
    xxl: 32px,
);

$line-heights: (
    xxs: 12px,
    xs: 14px,
    s: 18px,
    base: 20px,
    document: 24px,
    m: 26px,
    l: 28px,
    xl: 30px,
    xxl: 44px,
);

$font-weights: (
    bold:     700,
    semibold: 600,
    medium:   500,
    normal:   400,
);
