$small-modal-width: 90vw;

%small-res-modal {
    width: $small-modal-width !important;
    margin-left: math.div($small-modal-width, -2) !important;
}

.modal {
    @extend %small-res-modal;
}

.v--modal-box {
    &, &.medium, &.large {
        @extend %small-res-modal;
    }
}

.modal-header {
    max-height: box-model(xs) * 19;
}
