/// Creates the grid on the including element
///
/// @param {number} [$repeat=12] the repetition factor for the grid
/// @param {number} [$vertical-gap=40px] the grid's vertical gap
/// @param {number} [$horizontal-gap=24px] the grid's horizontal gap
@mixin grid($repeat: var(--container-max-columns), $vertical-gap: var(--grid-vertical-gap), $horizontal-gap: box-model(l)) {
  display: grid;
  grid-template-columns: repeat($repeat, 1fr);
  gap: $vertical-gap $horizontal-gap;
}

/// Defines the grid's column occupation fot the element
///
/// @param {integer} $size the desired size for this element
/// @param {integer} [$start=null] the number of the column where this element should start
@mixin cols($size: var(--container-max-columns), $start: null) {
  $gridColEnd: span calc( m#{i}n( var(--container-max-columns), #{$size} ));

  @if $start != null {
      --max-cols: calc(var(--container-max-columns) - #{$start} + 1);
      $gridColEnd: span calc( m#{i}n( var(--max-cols), #{$size} ));
      grid-column-start: #{$start};
  }

  grid-column-end: $gridColEnd;
}

/// Applies the font smoothing
@mixin font-smoothing($value: antialiased) {
  @if $value == antialiased {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  } @else {
      -webkit-font-smoothing: subpixel-antialiased;
      -moz-osx-font-smoothing: auto;
  }
}

/// Sets the font for this weight.
///
/// @param $family the font family to set.
/// @param $weight one of 'bold', 'semibold', 'normal' (default), or 'thin'
@mixin application-font($family, $weight: 'normal') {
  @include font-smoothing();
  @include font-weight($weight);

  font-family: $family;
}

/// Sets the primary font for this weight.
///
/// @param $weight one of 'bold', 'semibold', 'normal' (default), or 'thin'
@mixin primary-font($weight: 'normal') {
  @include application-font($base-font-family, $weight);
}

/// Sets the secondary font for this weight.
///
/// @param $weight one of 'bold', 'semibold', 'normal' (default), or 'thin'
@mixin secondary-font($weight: 'normal') {
    @include application-font($secondary-font, $weight);
}

/// Sets the font size for the specified element.
///
/// @param {string} $name the name of the element.
@mixin font-size($name) {
    font-size: map-get($font-sizes, $name);
    font-size: font-size($name);
}

/// Sets the font weight for the specified element.
///
/// @param {string} $name the name of the element.
@mixin font-weight($name) {
    font-weight: font-weight($name);
}

/// Sets the line height for the specified element.
///
/// @param {string} $name the name of the element.
@mixin line-height($name) {
    line-height: map-get($line-heights, $name);
    line-height: line-height($name);
}

/// Cross-browser support for @font-face. Gecko, Webkit, Opera.
///
/// @param {string} $name is required, arbitrary, and what you will use in font stacks.
/// @param {string[]} $font-files is required using font-files('relative/location', 'format'). for best results use this order: woff, opentype/truetype, svg
/// @param {boolean} [$weight=false] shows if the font is bold, defaults to normal
/// @param {string} [$style=normal] the font style
@mixin font-face($name, $font-files, $weight: false, $style: normal ) {
  @font-face {
      font-family: quote($name);
      src: $font-files;

      @if $weight {
          font-weight: $weight;
      }

      @if $style {
          font-style: $style;
      }
  }
}

/// Prevents floating content to not occupy place on the parent
@mixin clearfix {
  &::after {
      content: "";
      display: table;
      clear: both;
  }
}

/// Forces to break the text with an ellipsis.
@mixin ellipsis($nowrap: true) {
  @if $nowrap {
      white-space: nowrap;
  }

  overflow: hidden;
  text-overflow: ellipsis;
}

/// Use this to design an icon with the proper size and font-size
///
/// @param {string} $name the name of the icon size (one of xs, s, n, m l)
/// @param {number} [$ratio: $icon-font-size-ratio] ratio with which down or up size the font size.
@mixin icon-size($name, $ratio: $icon-font-size-ratio) {
  $size: icon-size($name);
  display: inline-block;
  width: $size;
  height: $size;
  line-height: $size;
  font-size: icon-font-size($name, $ratio);
  text-align: center;
}

/// Use this to clamp lines to the desired number of lines
///
/// @param {number} $clampSize number of line before ellipsis
@mixin clamp($clampSize) {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $clampSize;
    display: -webkit-box;
    overflow: hidden;
    white-space: normal;
}

/// Use this when you have to mind the gap between
/// flex cells
///
/// @param {number} $grow flex-grow
/// @param {number} $shrink flex-shrink
/// @param {number} $gap the parent flex gap between cells
/// @param {number} basis number of cells per flex
@mixin flexWithGap($grow, $shrink, $gap, $basis) {
  $totalGap: $gap * ($basis - 1);
  flex: $grow $shrink calc(#{percentage(math.div(1, $basis))} - #{math.div($totalGap, $basis)});
}
