footer.main {
    @include font-size(s);
    @include line-height(s);

    background-color: color(primary);
    color: color(button-text);
    text-align: center;
    padding: box-model(s);

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
