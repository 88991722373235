.registration {
    &.landing {
        main > .container {
            display: flex;
            flex-direction: column-reverse;

            .panel {
                display: block;
                width: auto;
                margin: box-model(0, 0, m);

                &.spotlight {
                    position: static;
                    margin-bottom: box-model(xxl);
                }

                .compliance.hidden-mid-down {
                    display: none;
                }

                .compliance.shown-mid-down {
                    display: block;
                }
            }
        }
    }

    &.sign-up, &.registered, &.updated {
        .inner-wrapper {
            @include cols(6, 1);
        }
    }

    &.warning-wall {
        .page {
            @include cols(4, 2);
        }
    }
}
