// Paths
// -----

$images-path: '../images';
$fonts-path: '../fonts';

// Base stuff
// ----------

$base-unit: 8px;

// Sizes
// -----

$global-sizes: (
    xxxs: math.div($base-unit , 4),    //  2px
    xxs:  math.div($base-unit , 2),    //  4px
    xs:   $base-unit,                  //  8px
    s:    $base-unit + math.div($base-unit, 2), // 12px
    m:    $base-unit * 2,              // 16px
    l:    $base-unit * 3,              // 24px
    xl:   $base-unit * 4,              // 32px
    xxl:  $base-unit * 5,              // 40px
    xxxl: $base-unit * 6,              // 48px
    h:    $base-unit * 8,              // 64px
);

$icons-size: (
    xxs: $base-unit * 2, // 16px
    xs:  $base-unit * 3, // 24px
    s:   $base-unit * 4, // 32px
    m:   $base-unit * 5, // 40px
    l:   $base-unit * 6, // 48px
    xl:  $base-unit * 7, // 56px
    xxl: $base-unit * 8, // 64px
    xxxl: $base-unit * 9, // 72px
);

$icon-side-margin: 10px;
$icon-font-size-ratio: 75; // It means that the font size it will be 75% of the icon size.

$base-border-radius: box-model(xs);
$small-border-radius: box-model(xxs);
$large-border-radius: box-model(s);

// Scaffolding
// -----------

@import "variables/colors";
@import "variables/grid";
@import "variables/typography";

// Components
// ----------

$inferred-header-height: 56px;

// Buttons
// -------

$close-button-size: box-model(xl);

// Modals
// ------

$modal-width-small: 392px;
$modal-width-medium: 584px;
$modal-width-large: 848px;

$modal-viewport-offset: $base-unit * 10;
$modal-base-max-height: calc(100vh - #{$modal-viewport-offset});
$modal-backdrop-opacity: 0.3;
$modals-width-transition:
    width .1s ease-in-out,
    transform .1s ease-in-out,
    margin-left .1s ease-in-out;
