.cookies-notification {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    transform: translateY(100%);
    transition: transform 0.2s ease-in-out;
    z-index: 500;

    &.in {
        transform: translateY(0);
    }

    p {
        text-align: center;
        color: #fff;
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    button {
        margin: 0 10px;
    }

    a {
        text-decoration: underline;
        color: #fff;
    }
}
