.sponsors {
    $gap: box-model(m);

    display: flex;
    flex-wrap: wrap;
    gap: $gap;

    .sponsor-card {
        @include flexWithGap(0, 0, $gap, 3);
    }
}

*[class^="sponsor-"] {
    svg {
        background-color: color(primary);
        border-radius: 100%;

        path {
            fill: var(--button-foreground-color);
        }
    }
}

.sponsor-card {
    box-sizing: border-box;
    display: block;
    border: 1px solid;
    border-color: color(base-100);
    border-radius: $base-border-radius;
    padding: box-model(m, xs);
    text-align: center;

    picture {
        display: block;
        margin-bottom: box-model(xs);
        font-size: 0;
        line-height: 0;

        img {
            height: box-model(xs) * 10;
            object-fit: cover;
        }
    }

    .details {
        @include font-size(s);
        @include line-height(s);

        display: block;
        margin-bottom: box-model(xxs);
    }

    .title {
        @include font-size(base);
        @include font-weight(medium);
        @include line-height(base);
        @include clamp(2);
    }
}

.sponsor-details {
    overflow-wrap: break-word;

    .modal-header {
        text-align: center;
        padding-bottom: 0;
        flex: 1 1 20%;
        line-height: 1;
    }

    .modal-body {
        padding-top: box-model(s);
        flex: 1 1 80%;
    }

    picture {
        display: block;
        margin: box-model(l) auto box-model(xxs);
        font-size: 0;
        line-height: 0;

        img {
            height: box-model(xs) * 10;
            object-fit: cover;
        }
    }

    .details {
        text-align: center;
        margin-bottom: box-model(xs);

        .title {
            @include font-weight(medium);
            @include font-size(base);
            @include line-height(base);
        }
    }

    .meta {
        @include font-size(s);
        @include line-height(s);

        color: color(base-300);
        padding-bottom: box-model(m);
        text-align: center;

        &.has-bio {
            @include ellipsis;
            white-space: initial;
            border-bottom: 1px solid color(base-100);
            margin-bottom: box-model(m);
        }

        a {
            color: color(primary);
            text-decoration: none;
        }

        a, span {
            &::after {
                color: color(base-500);
                content: '•';
                display: inline-block;
                margin: box-model(0, xs);
            }

            &:last-child::after {
                display: none;
            }
        }
    }

    .close {
        margin: 0;
        top: box-model(l);
        right: box-model(l);
        position: absolute;
        z-index: 2;
    }
}

.sponsor-card,
.sponsor-details {
    .level {
        @include font-size(s);
        @include font-weight(bold);
        @include line-height(xs);

        display: inline-block;
        padding: box-model(xxxs, xs);
        border-radius: $base-border-radius;
        text-transform: uppercase;

        &:has(+ *) {
            margin-bottom: box-model(xs);
        }
    }
}
