// ===========================
// Defaults and common devices
// ===========================

@import "default/base";

// ===========
// Wide screen
// ===========

// Wide screen up
@media (min-width: $screen-xxl-min) {
    /// Rules or import for wide
}

// ===========
// Extra Large
// ===========

// Extra large up
@media (min-width: $screen-xl-min) {
    /// Rules or import for extra-large-up

    :root {
        --container-max-width: #{$screen-xl-min};
    }
}

// Extra large only
@media (min-width: $screen-xl-min) and (max-width: $screen-xl-max) {
    /// Rules or import for extra-large
}

// Extra large down
@media (max-width: $screen-xl-max) {
    /// Rules or import for extra-large-down
}

// =====
// Large
// =====

// Large up
@media (min-width: $screen-lg-min) {
    /// Rules or import for large-up
}

// Large only
@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    /// Rules or import for large
}

// Large down
@media (max-width: $screen-lg-max) {
    /// Rules or import for large-down
}

// ===========
// Middle size
// ===========

// Mid up
@media (min-width: $screen-md-min) {
    /// Rules or import for  mid-up
}

// Mid only
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    /// Rules or import for mid
}

// Mid down
@media (max-width: $screen-md-max) {
    /// Rules or import for mid-down

    @import "mid-down/commons";

    @import "mid-down/pages/registrations";
}

// ==========
// Small size
// ==========

// Small up
@media (min-width: $screen-sm-min) {
    /// Rules or import for small-up
}

// Small only
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    /// Rules or import for small
}

// Small down
@media (max-width: $screen-sm-max) {
    /// Rules or import for small-down

    @import "small-down/commons";
    @import "small-down/modals";

    @import "small-down/components/forms";
    @import "small-down/components/main-header";
    @import "small-down/components/speakers";
    @import "small-down/components/sponsors";

    @import "small-down/pages/registration";
}

// ===========
// Extra small
// ===========

// Extra small only
@media (max-width: $screen-xs-max) {
    /// Rules or import for extra-small
}
