@import "vue-multiselect/dist/vue-multiselect.min";

/**
 * Vue Multiselect
 * https://vue-multiselect.js.org/
 */
.multiselect.form-control {
    color: color(base-700);
    padding: 0;
    height: auto;
    min-height: 0;
    border: 0;

    &.multiselect--active {
        .toggler {
            transform: rotate(180deg);
        }

        .multiselect__input {
            border-radius: $base-border-radius;
        }
    }

    &.multiselect--disabled {
        opacity: 1;
        cursor: not-allowed !important;

        .multiselect__tags {
            background-color: color(base-100);
            border-color: color(base-100);
            color: color(base-500);
        }
    }

    &.has-error {
        .multiselect__tags {
            border-color: color(error-300);
        }
    }

    .toggler {
        position: absolute;
        top: 0;
        right: 0;
        height: box-model(xxl);
        width: box-model(xxl);
        text-align: center;
        line-height: box-model(xxl); // no mixin needed here. icon's specific.
        z-index: 1;

        .icon-chevron-down {
            line-height: box-model(xxl);
            width: 100%;
            display: block;
        }
    }

    .multiselect__tags {
        padding: 0;
        border-color: color(base-100);
        min-height: box-model(xxl);
        border-radius: $base-border-radius;
        line-height: 1;

        &:focus-within {
            border-color: color(primary);
            box-shadow: 0 0 0 1px color(primary);
            line-height: 1;
        }
    }

    .multiselect__input,
    .multiselect__placeholder,
    .multiselect__single {
        @include font-size(base);
        @include line-height(xl);
        @include ellipsis;

        padding: box-model(xxs, s);
        border: 0;
        margin: 0;
        max-width: 95%;
        min-height: 0;
        background-color: transparent;
        display: block;

        &:focus {
            box-shadow: none !important;
        }
    }

    .multiselect__input,
    .multiselect__placeholder {
        $placeholder-color: color(base-300);

        color: $placeholder-color;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $placeholder-color;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $placeholder-color;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
            color: $placeholder-color;
        }
    }

    i {
        &.multiselect-left-icon{
            margin-right: box-model(xs);
        }
    }
}

.multiselect__content-wrapper {
    background-color: color(base-000);
    border: 1px solid color(base-100) !important;
    border-radius: $base-border-radius;
    box-sizing: border-box;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.2);;
    padding: 0;
    margin-top: box-model(xs);
    z-index: z-index(dropdowns);
}

.multiselect__option {
    @include line-height(base);
    padding: box-model(xs, s);
    min-height: box-model(xxl);

    &::after {
        display: none;
    }
}

.multiselect__option--highlight {
    color: color(button-text);
    background-color: color(primary);
}

.multiselect__option--selected,
.multiselect__option--selected.multiselect__option--highlight {
    @include font-weight(normal);
    color: color(base-000);
    background-color: color(primary);
}

.multiselect__tags-wrap {
    display: block;
    line-height: 0;
    font-size: 0;
}

.multiselect__tag {
    @include font-size(s);
    @include line-height(xs);
    margin: box-model(xxs);
    background-color: color(base-300);
    color: color(base-700);
}

.multiselect__tag-icon {
    &::after {
        color: color(base-700);
    }

    &:focus,
    &:hover {
        background-color: color(base-300);

        &::after {
            color: color(error-300);
        }
    }
}

.multiselect--above .multiselect__content-wrapper {
    margin-bottom: box-model(xs);
    border: 1px solid color(base-500);
    border-radius: $base-border-radius;
}
