.compliance {
    border-radius: $base-border-radius;
    padding: box-model(m);

    &.has-description {
        display: none;
    }
}

.compliance[style*="--link-color"] {
    a {
        color: var(--link-color);
    }
}
