.calendar-button {
    li {
        min-width: box-model(h) * 4;
    }

    a {
        @include font-size(base);
        @include line-height(document);

        color: color(base-500);
        display: block;
        padding: box-model(xs);
        text-decoration: none;

        &:hover {
            background-color: color(base-050);
        }

        span {
            vertical-align: middle;
            margin-left: box-model(s);
        }
    }
}
